// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.banner {
  width: 100%;
  height: 75px;
  -webkit-backdrop-filter: blur(200px);
          backdrop-filter: blur(200px);
  text-align: center;
}

.banner h1 {
  font-size: 3rem;
  color: #00e5ff;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
  margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/Banner.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,oCAA4B;UAA5B,4BAA4B;EAC5B,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,cAAc;EACd,+EAA+E;EAC/E,SAAS;AACX","sourcesContent":[".banner {\n  width: 100%;\n  height: 75px;\n  backdrop-filter: blur(200px);\n  text-align: center;\n}\n\n.banner h1 {\n  font-size: 3rem;\n  color: #00e5ff;\n  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;\n  margin: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
