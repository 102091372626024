import React from 'react';
import './Banner.css'; // Import the CSS for the banner

const Banner = () => {
  return (
    <div className="banner">
        <h1>NickLiftWeight</h1>
    </div>
  );
};

export default Banner;